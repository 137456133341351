import type { DefaultComponentType } from '../../../../src/utils/types';
import '../../../../src/components/protons/icon/Icon';
import '../../atoms/anchor/Anchor';
import '../../atoms/button/Button';
import './StatusPanel.pcss';

export enum StatusPanelVariant {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info'
}

type StatusPanelCtaProps = {
    label: string;
    href?: string;
};

export type StatusPanelProps = DefaultComponentType & {
    variant: StatusPanelVariant;
    label: string;
    cta?: StatusPanelCtaProps;
    hidden?: boolean;
    heading?: string;
};
