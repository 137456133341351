import {
    convertPriceStringToNumber,
    getCategoryName,
    getContentGroup,
    getPosition
} from '../util/dataLayer';
import BigQuery, { BigQueryEventParamsType } from './BigQuery';
import GoogleAnalyticsEventBase from './GoogleAnalyticsEventsBase';

class GoogleAnalyticsEvent extends GoogleAnalyticsEventBase {
    public productListClick(
        listName: string,
        price: number | undefined,
        house: HouseSearchListItem,
        index: number
    ): void {
        const ga4Data: GoogleAnalytics4SelectItemEvent = {
            item_list_id: listName,
            item_list_name: listName,
            items: [
                {
                    item_id: house.id.toString(),
                    currency: 'EUR',
                    price: price,
                    item_brand: 'Natuurhuisje',
                    index: getPosition(index + 1),
                    item_category: getCategoryName()
                }
            ]
        };

        this.trackEvent<GoogleAnalytics4SelectItemEvent>('select_item', ga4Data);
    }

    public trackSearchForm(listName: string, houses: HouseSearchListItem[], total: number): void {
        const ga4items: GoogleAnalytics4Item[] = [];
        const bigQueryItems: { id: string; index: number }[] = [];
        const biQueryPriceItems: { price: number; index: number }[] = [];

        houses.forEach((house, index) => {
            const priceString = house?.price?.dayPrice;
            const trackingIndex = getPosition(index + 1);
            const trackingPrice = convertPriceStringToNumber(priceString) ?? undefined;

            ga4items.push({
                item_id: house.id.toString(),
                currency: 'EUR',
                price: trackingPrice,
                item_brand: 'Natuurhuisje',
                index: trackingIndex,
                item_category: getCategoryName(),
                item_variant: house.externalId ? 'belvilla' : 'own'
            });

            bigQueryItems.push({
                id: house.id.toString(),
                index: trackingIndex
            });

            biQueryPriceItems.push({
                price: trackingPrice ?? 0,
                index: trackingIndex
            });
        });

        const ga4Data: GoogleAnalytics4ViewItemListEvent = {
            item_list_id: listName,
            item_list_name: listName,
            items: ga4items
        };

        this.trackEvent<GoogleAnalytics4ViewItemListEvent>('view_item_list', ga4Data);

        const eventParams: BigQueryEventParamsType[] = [
            {
                eventKey: 'item_list_id',
                eventValue: listName
            },
            {
                eventKey: 'item_list_name',
                eventValue: listName
            },
            {
                eventKey: 'items',
                eventValue: JSON.stringify(bigQueryItems)
            },
            {
                eventKey: 'prices',
                eventValue: JSON.stringify(biQueryPriceItems)
            },
            {
                eventKey: 'content_group',
                eventValue: getContentGroup()
            },
            {
                eventKey: 'item_count',
                eventValue: `${total}`
            }
        ];

        BigQuery.track({
            eventName: 'view_item_list',
            eventParams: eventParams
        });
    }

    public async trackSearchFilterCloseDialog(total: number): Promise<void> {
        const eventParams: BigQueryEventParamsType[] = [
            {
                eventKey: 'item_count',
                eventValue: `${total}`
            }
        ];

        await BigQuery.track({
            eventName: 'search_filter_close_dialog',
            eventParams: eventParams
        });
    }

    public addToCart(
        dataLayer: StandardObjectInterface,
        totalPrice: number,
        pricePerNight: string
    ): void {
        const products: GoogleAnalytics4Item[] = [];

        dataLayer.products.forEach((product: StandardObjectInterface) => {
            products.push({
                item_id: product.id.toString(),
                currency: 'EUR',
                price: Number(pricePerNight),
                item_brand: 'Natuurhuisje',
                item_category: getCategoryName(),
                quantity: 1,
                item_variant: product.type
            });
        });

        const ga4Data: GoogleAnalytics4ItemEvent = {
            currency: 'EUR',
            value: totalPrice,
            items: products
        };

        this.trackEvent<GoogleAnalytics4ItemEvent>('add_to_cart', ga4Data);

        BigQuery.track({
            eventName: 'add_to_cart',
            eventParams: [
                {
                    eventKey: 'products',
                    eventValue: JSON.stringify(products)
                }
            ]
        });
    }

    public searchImpression(): void {
        this.trackEvent('search_impression');
    }

    public productDetailImpression(house: dataLayerEcommerceProduct, price: number | null): void {
        const ga4Item: GoogleAnalytics4Item = {
            item_id: house.id.toString(),
            currency: 'EUR',
            item_brand: 'Natuurhuisje',
            item_variant: house.type,
            item_category: getCategoryName()
        };

        if (price) {
            ga4Item.price = price;
        }

        const ga4Data: GoogleAnalytics4ItemEvent = {
            currency: 'EUR',
            value: 0,
            items: [ga4Item]
        };

        this.trackEvent<GoogleAnalytics4ItemEvent>('view_item', ga4Data);

        const eventParams: BigQueryEventParamsType[] = [
            {
                eventKey: 'house_id',
                eventValue: house.id.toString()
            }
        ];

        BigQuery.track({
            eventName: 'view_item',
            eventParams: eventParams
        });
    }

    public trackBookingIntention(data: GoogleAnalytics4BookingIntentionEvent): void {
        this.trackEvent<GoogleAnalytics4BookingIntentionEvent>('checkout_booking_intention', data);
    }

    public viewPromotion(data: GoogleAnalytics4PromotionEvent): void {
        this.trackEvent<GoogleAnalytics4PromotionEvent>('view_promotion', data);
    }

    public selectPromotion(data: GoogleAnalytics4PromotionEvent): void {
        this.trackEvent<GoogleAnalytics4PromotionEvent>('select_promotion', data);
    }

    public toggleFavourite(data: GoogleAnalytics4FavouriteAdd): void {
        this.trackEvent<GoogleAnalytics4FavouriteAdd>('toggle_favourite', data);
    }

    public trackCheckoutMobileDetailsModal(): void {
        this.trackEvent('open_checkout_mobile_details');
    }

    public cookieBannerImpression(): void {
        this.trackEvent('cookie_banner_impression');
    }

    public cookieBannerModal(): void {
        this.trackEvent('cookie_banner_modal');
    }

    public cookieBannerSave(consent: GoogleAnalytics4ConsentModeEvent, reason: string): void {
        this.trackEvent<GoogleAnalytics4CookieBannerSaveEvent>('cookie_banner_save', {
            reason: reason,
            ...consent
        });
    }

    public updateConsentMode(data: GoogleAnalytics4ConsentModeEvent): void {
        this.trackConsent('update', data);
    }
}

export { GoogleAnalyticsEvent };
export default new GoogleAnalyticsEvent();
