export default class ProgressBar extends HTMLElement {
    #onFocus = (event: Event): void => {
        if (event.target === window) this.play();
    };

    #onBlur = (event: Event): void => {
        if (event.target === window) this.pause();
    };

    #onAnimationEnd = (event: Event): void => {
        event.stopPropagation();
        this.dispatchEvent(new Event('done'));
    };

    constructor(delay: number) {
        super();
        this.#initStyles(delay);
    }

    play(): void {
        this.style.animationPlayState = 'running';
    }

    pause(): void {
        this.style.animationPlayState = 'paused';
    }

    protected connectedCallback(): void {
        this.#initEventListeners();

        if (window.document.hasFocus()) {
            this.play();
        }
    }

    protected disconnectedCallback(): void {
        this.pause();
        this.#removeEventListeners();
    }

    #initStyles(delay: number): void {
        this.style.animationDuration = `${delay}ms`;
        this.style.animationPlayState = 'paused';
    }

    #initEventListeners(): void {
        this.addEventListener('animationend', this.#onAnimationEnd);
        window.addEventListener('focus', this.#onFocus);
        window.addEventListener('blur', this.#onBlur);
    }

    #removeEventListeners(): void {
        this.removeEventListener('animationend', this.#onAnimationEnd);
        window.removeEventListener('focus', this.#onFocus);
        window.removeEventListener('blur', this.#onBlur);
    }
}

if (!window.customElements.get('nh-notification-progress-bar')) {
    window.customElements.define('nh-notification-progress-bar', ProgressBar);
}
