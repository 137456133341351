var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/button/Button.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]),["nh-button"]);
frame.set("classes", t_1, true);
if(frame.topLevel) {
context.setVariable("classes", t_1);
}
if(frame.topLevel) {
context.addExport("classes", t_1);
}
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "icon")) === true && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"") != "" && (!env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "label")) === true || env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "label"),"") == "")) {
var t_2;
t_2 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-button--icon"]);
frame.set("classes", t_2, true);
if(frame.topLevel) {
context.setVariable("classes", t_2);
}
if(frame.topLevel) {
context.addExport("classes", t_2);
}
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "variant"),"") != "") {
var t_3;
t_3 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-button--" + "" + runtime.contextOrFrameLookup(context, frame, "variant") + "" + ""]);
frame.set("classes", t_3, true);
if(frame.topLevel) {
context.setVariable("classes", t_3);
}
if(frame.topLevel) {
context.addExport("classes", t_3);
}
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "size"),"") != "") {
var t_4;
t_4 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-button--" + "" + runtime.contextOrFrameLookup(context, frame, "size") + "" + ""]);
frame.set("classes", t_4, true);
if(frame.topLevel) {
context.setVariable("classes", t_4);
}
if(frame.topLevel) {
context.addExport("classes", t_4);
}
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "iconPosition"),"") != "" && runtime.contextOrFrameLookup(context, frame, "iconPosition") == "right") {
var t_5;
t_5 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-button--icon-right"]);
frame.set("classes", t_5, true);
if(frame.topLevel) {
context.setVariable("classes", t_5);
}
if(frame.topLevel) {
context.addExport("classes", t_5);
}
;
}
output += "<button\n    is=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "is"),"nh-button"), env.opts.autoescape);
output += "\"\n    type=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "type"),"button"), env.opts.autoescape);
output += "\"\n    class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "id"),null) != null) {
output += "id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "variant"),"") != "") {
output += "variant=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "variant"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "size"),"") != "") {
output += "size=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "size"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "formId"),"") != "") {
output += "form=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "formId"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "action"),false) != false) {
output += " action";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "loading"),false) != false) {
output += " loading";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "disabled"),false) != false) {
output += " disabled";
;
}
frame = frame.push();
var t_8 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"),{});
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_6;
if(runtime.isArray(t_8)) {
var t_7 = t_8.length;
for(t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6][0];
frame.set("[object Object]", t_8[t_6][0]);
var t_10 = t_8[t_6][1];
frame.set("[object Object]", t_8[t_6][1]);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += " ";
output += runtime.suppressValue(t_9, env.opts.autoescape);
if(t_10) {
output += "=\"";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_6 = -1;
var t_7 = runtime.keys(t_8).length;
for(var t_11 in t_8) {
t_6++;
var t_12 = t_8[t_11];
frame.set("name", t_11);
frame.set("value", t_12);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += " ";
output += runtime.suppressValue(t_11, env.opts.autoescape);
if(t_12) {
output += "=\"";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n>";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "icon")) === true && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"") != "") {
output += "\n        ";
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/atoms/button/Button.html.njk", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
context.setVariable("iconTemplate", t_13);
output += "\n        ";
output += runtime.suppressValue((lineno = 33, colno = 30, runtime.callWrap(runtime.memberLookup((t_13),"render"), "iconTemplate[\"render\"]", context, [{"name": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"size": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"size"),1),"custom": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"custom")),"brand": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"brand"))}])), env.opts.autoescape);
})});
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "label"),"") != "") {
output += "\n        <span class=\"nh-button__label\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "label"), env.opts.autoescape);
output += "</span>";
;
}
output += "</button>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/button/Button.html.njk"] , dependencies)