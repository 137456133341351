import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import LoginDialog from './LoginDialog';

export default class LoginManager extends AbstractSubject {
    static #instance: LoginManager | null = null;

    static getInstance(): LoginManager {
        if (this.#instance === null) {
            this.#instance = new LoginManager();
        }

        return this.#instance;
    }

    public static resetInstance(): LoginManager {
        this.#instance = new LoginManager();
        return this.#instance;
    }

    #isLoggedIn: boolean = document.body.classList.contains('userAvailable');

    readonly #loginDialog: LoginDialog = LoginDialog.getInstance();

    readonly #loginDialogToggles: HTMLElement[] = [];

    readonly #registerDialogToggles: HTMLElement[] = [];

    get isLoggedIn(): boolean {
        return this.#isLoggedIn;
    }

    set isLoggedIn(value: boolean) {
        if (this.isLoggedIn === value) {
            return;
        }

        this.#isLoggedIn = value;
        this.notify();
    }

    private constructor() {
        super();

        if (this.#isLoggedIn) {
            return;
        }

        this.#loginDialogToggles = Array.from(
            document.querySelectorAll('[data-role="login-link"]')
        );

        this.#registerDialogToggles = Array.from(
            document.querySelectorAll('[data-role="guest-register-link"]')
        );

        this.#handleLoginDialog();
        this.#handleGuestRegisterDialog();
    }

    #handleLoginDialog(): void {
        this.#loginDialogToggles.forEach((toggle) =>
            toggle.addEventListener('click', (e: Event): void => {
                e.preventDefault();
                this.#loginDialog.show();
            })
        );
    }

    #handleGuestRegisterDialog(): void {
        this.#registerDialogToggles.forEach((toggle) =>
            toggle.addEventListener('click', (e: Event): void => {
                e.preventDefault();
                this.#loginDialog.close();
            })
        );
    }
}
