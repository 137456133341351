/* eslint-disable import/no-duplicates */
import '../../../../src/components/protons/icon/Icon';
import Icon from '../../../../src/components/protons/icon/Icon';
/* eslint-enable import/no-duplicates */
import ProgressBar from './ProgressBar';
import '../../molecules/notifications/Notifications.pcss';

enum ProgressBarAction {
    PLAY = 'play',
    PAUSE = 'pause'
}
export enum NotificationType {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success'
}

const IconType: Record<string, string> = {
    [NotificationType.INFO]: 'circle-info',
    [NotificationType.WARNING]: 'warning',
    [NotificationType.ERROR]: 'error',
    [NotificationType.SUCCESS]: 'success'
};

export type NotificationOptions = {
    type?: NotificationType;
    content: string;
    delay?: number;
};

const defaultOptions: NotificationOptions = {
    type: NotificationType.INFO,
    content: '',
    delay: 0
};

export default class Notification extends HTMLElement {
    #closeIcon: HTMLElement | null = null;

    options: NotificationOptions;

    #progressBar: ProgressBar | undefined;

    constructor(options: NotificationOptions) {
        super();

        this.options = { ...defaultOptions, ...options };

        this.#initType();

        const html = this.innerHTML;
        this.innerText = '';

        this.attachShadow({ mode: 'open' });

        const delay = this.getAttribute('delay') || this.options.delay?.toString() || '0';
        this.setAttribute('delay', delay);

        this.#render();
        const card = this.#renderText(this.options.content || html);

        if (Number(delay) > 0) {
            this.#renderProgress(card);
        }
    }

    static get observedAttributes(): string[] {
        return ['show', 'hide'];
    }

    public show(): void {
        if (this.#progressBar) {
            this.#progressBar.play();
            this.#progressBar.addEventListener('done', () => {
                this.toggleAttribute('hide', true);
            });
        }
    }

    public hide(): void {
        this.toggleAttribute('show', false);
        this.addEventListener('animationend', () => {
            this.remove();
        });
    }

    protected connectedCallback(): void {
        this.toggleAttribute('show', true);

        this.addEventListener(
            'mouseover',
            this.#progressBarHandler.bind(this, ProgressBarAction.PAUSE)
        );

        this.addEventListener(
            'mouseout',
            this.#progressBarHandler.bind(this, ProgressBarAction.PLAY)
        );

        this.#closeIcon = this.shadowRoot?.querySelector('[part="close-icon"]') || null;
        this.#closeIcon?.addEventListener('click', this.#closeHandler.bind(this));
    }

    protected disconnectedCallback(): void {
        this.#closeIcon?.removeEventListener('click', this.#closeHandler.bind(this));
        this.removeEventListener(
            'mouseover',
            this.#progressBarHandler.bind(this, ProgressBarAction.PAUSE)
        );
        this.removeEventListener(
            'mouseout',
            this.#progressBarHandler.bind(this, ProgressBarAction.PLAY)
        );
    }

    protected attributeChangedCallback(name: string): void {
        if (name === 'show') {
            this.show();
        }

        if (name === 'hide') {
            this.hide();
        }
    }

    #progressBarHandler(action: ProgressBarAction): void {
        if (action === ProgressBarAction.PLAY) {
            this.#progressBar?.play();
            return;
        }

        if (action === ProgressBarAction.PAUSE) {
            this.#progressBar?.pause();
        }
    }

    #closeHandler(): void {
        this.toggleAttribute('hide', true);
        this.dispatchEvent(new Event('closed'));
    }

    #initType(): void {
        const type = this.options.type || NotificationType.INFO;

        if (!this.hasAttribute('type') && Object.values(NotificationType).includes(type)) {
            this.setAttribute('type', type);
        }
    }

    #render(): void {
        if (!this.shadowRoot) return;

        const type = IconType[this.options.type || ''];

        if (type) {
            const typeIcon = new Icon();
            typeIcon.name = type;
            typeIcon.size = '1';
            typeIcon.setAttribute('part', 'icon');
            this.shadowRoot.append(typeIcon);
        }

        const closeIcon = new Icon();
        closeIcon.name = 'close';
        closeIcon.size = '1';

        closeIcon.setAttribute('part', 'close-icon');

        const content = document.createElement('slot');
        content.setAttribute('name', 'content');

        this.shadowRoot.append(content, closeIcon);
    }

    #renderText(text?: string): HTMLElement {
        const card = document.createElement('DIV');
        card.setAttribute('slot', 'content');
        card.innerHTML = text || '';
        this.append(card);

        return card;
    }

    #renderProgress(card: HTMLElement): void {
        if (!this.hasAttribute('delay')) return;

        const delay = Number(this.getAttribute('delay'));
        this.#progressBar = new ProgressBar(delay);
        this.#progressBar.setAttribute('part', 'progress-bar');
        card.append(this.#progressBar);
    }
}

if (!window.customElements.get('nh-notification-item')) {
    window.customElements.define('nh-notification-item', Notification);
}
