import { onIntersectionObserver } from '@naturehouse/nh-essentials/lib/events/eventHandling';
import { matchMediaAddEventListener } from '@naturehouse/nh-essentials/lib/polyfills/matchMedia';
import curtainEvents from '../events/curtainEvents';
import '../../css/components/_accordion.pcss';
import '../../css/components/_curtain.pcss';

interface ICurtainBindings {
    collapsedHeight: number;
    initializeOnViewportChange: boolean | undefined;
    emitEventsOnChange: boolean;
    openOnLoad: boolean;
    activeOnDevice: string;
}

interface ICurtainContext {
    element: HTMLElement;
    bindings: ICurtainBindings;
}

export default class Curtain {
    private readonly context: ICurtainContext;

    private readonly content: HTMLElement;

    private readonly button: HTMLButtonElement;

    private readonly collapsedHeight: number;

    private readonly nestedCurtains: HTMLElement[];

    private readonly openClass = 'nh-curtain__content--open';

    private readonly clickListener: EventListener;

    private readonly transitionListener: EventListener;

    constructor(context: ICurtainContext) {
        this.context = context;
        this.content = this.context.element.querySelector(
            '[data-curtain-content], .nh-navigation-dropdown__menu__list'
        ) as HTMLElement;
        this.button = this.context.element.querySelector(
            '[data-curtain-button], .nh-dropdown-menu__item'
        ) as HTMLButtonElement;
        this.collapsedHeight = this.context.bindings.collapsedHeight;
        this.nestedCurtains = Array.from(this.context.element.querySelectorAll('[data-curtain]'));

        this.clickListener = (): void => {
            this.toggle();
        };

        this.transitionListener = (): void => {
            this.handleTransition();
        };
    }

    public onInit(): void {
        if (!this.isValid()) {
            return;
        }

        this.initialize();

        const mq: MediaQueryList = window.matchMedia('(min-width: 1024px)');

        if (this.context.bindings.initializeOnViewportChange) {
            matchMediaAddEventListener(mq, () => {
                this.initialize();
            });
        }

        if ('IntersectionObserver' in window) {
            onIntersectionObserver(this.context.element, () => {
                this.initialize();
            });
        }
    }

    private initialize(): void {
        if (!this.isVisible(this.context.element)) {
            return;
        }

        const mediaQuery: StandardObjectInterface = {
            mobile: '(max-width: 1023px)',
            desktop: '(min-width: 1024px)'
        };

        if (
            this.context.bindings.activeOnDevice &&
            (this.context.bindings.activeOnDevice === 'mobile' ||
                this.context.bindings.activeOnDevice === 'desktop')
        ) {
            const mq: MediaQueryList = window.matchMedia(
                mediaQuery[this.context.bindings.activeOnDevice]
            );
            if (!mq.matches) {
                this.content.style.maxHeight = '';
                return;
            }
        }

        if (this.collapsedHeight && this.content.scrollHeight <= this.collapsedHeight) {
            if (this.button) {
                this.button.classList.add('hidden');
            }
            this.content.style.maxHeight = 'none';
            return;
        }

        if (this.button) {
            this.button.classList.remove('hidden');
        }

        this.content.style.maxHeight = `${String(this.collapsedHeight)}px`;
        this.setEventListeners();

        if (this.context.bindings.openOnLoad) {
            this.open();
        }
    }

    private setEventListeners(): void {
        if (this.button) {
            this.button.removeEventListener('click', this.clickListener);
            this.button.addEventListener('click', this.clickListener);
        }

        this.nestedCurtains.forEach((curtain: HTMLElement) => {
            const content: HTMLElement = curtain.querySelector(
                '[data-curtain-content]'
            ) as HTMLElement;
            content.addEventListener('transitionend', () => {
                this.recalculate();
            });
        });

        this.context.element.addEventListener(
            curtainEvents.recalculate,
            this.recalculate.bind(this)
        );
        this.context.element.addEventListener(curtainEvents.open, this.open.bind(this));
        this.context.element.addEventListener(curtainEvents.close, this.close.bind(this));

        this.content.removeEventListener('transitionend', this.transitionListener);
        this.content.addEventListener('transitionend', this.transitionListener);
    }

    private isVisible(element: HTMLElement): boolean {
        return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
    }

    private isValid(): boolean {
        if (!this.collapsedHeight || !this.content) {
            return false;
        }

        return true;
    }

    private toggle(): void {
        if (this.content.classList.contains(this.openClass)) {
            this.close();
            return;
        }

        this.open();
    }

    private recalculate(): void {
        this.content.style.maxHeight = `${this.content.scrollHeight}px`;
    }

    private open(): void {
        if (this.button && this.button.dataset.less) {
            this.replaceButtonText(this.button, this.button.dataset.less);
        }

        this.context.element.classList.add('nh-curtain--active');
        this.content.classList.add(this.openClass);
        this.content.style.maxHeight = `${this.content.scrollHeight}px`;
    }

    private close(): void {
        if (this.button && this.button.dataset.more) {
            this.replaceButtonText(this.button, this.button.dataset.more);
        }

        this.context.element.classList.remove('nh-curtain--active');
        this.content.classList.remove(this.openClass);
        this.content.style.maxHeight = `${this.collapsedHeight}px`;
    }

    private handleTransition(): void {
        if (this.content.classList.contains(this.openClass)) {
            return;
        }

        this.content.style.maxHeight = this.collapsedHeight ? String(this.collapsedHeight) : '';
    }

    private replaceButtonText(bttn: HTMLButtonElement, text: string): void {
        const textElement: HTMLElement =
            bttn.querySelector('.nh-curtain__content__toggle__text') || bttn;
        textElement.textContent = text;
    }

    static getClassName(): string {
        return 'Curtain';
    }
}
