var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );
dependencies["@naturehouse/design-system/components/atoms/anchor/Anchor.html.njk"] = require( "@naturehouse/design-system/components/atoms/anchor/Anchor.html.njk" );
dependencies["@naturehouse/design-system/components/atoms/button/Button.html.njk"] = require( "@naturehouse/design-system/components/atoms/button/Button.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/status-panel/StatusPanel.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/molecules/status-panel/StatusPanel.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
output += "\n\n";
var t_4;
t_4 = runtime.contextOrFrameLookup(context, frame, "variant");
frame.set("iconName", t_4, true);
if(frame.topLevel) {
context.setVariable("iconName", t_4);
}
if(frame.topLevel) {
context.addExport("iconName", t_4);
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "variant") == "info") {
output += "\n    ";
var t_5;
t_5 = "circle-info";
frame.set("iconName", t_5, true);
if(frame.topLevel) {
context.setVariable("iconName", t_5);
}
if(frame.topLevel) {
context.addExport("iconName", t_5);
}
output += "\n";
;
}
output += "\n\n<div\n    class=\"nh-status-panel nh-status-panel--";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "variant"), env.opts.autoescape);
output += "\"";
frame = frame.push();
var t_8 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"));
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_6;
if(runtime.isArray(t_8)) {
var t_7 = t_8.length;
for(t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6][0];
frame.set("[object Object]", t_8[t_6][0]);
var t_10 = t_8[t_6][1];
frame.set("[object Object]", t_8[t_6][1]);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += " ";
output += runtime.suppressValue(t_9, env.opts.autoescape);
if(t_10) {
output += "=\"";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "\" ";
;
}
;
}
} else {
t_6 = -1;
var t_7 = runtime.keys(t_8).length;
for(var t_11 in t_8) {
t_6++;
var t_12 = t_8[t_11];
frame.set("name", t_11);
frame.set("value", t_12);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += " ";
output += runtime.suppressValue(t_11, env.opts.autoescape);
if(t_12) {
output += "=\"";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += "\" ";
;
}
;
}
}
}
frame = frame.pop();
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "hidden")) === true && runtime.contextOrFrameLookup(context, frame, "hidden")) {
output += " hidden";
;
}
output += "\n>\n    ";
output += runtime.suppressValue((lineno = 13, colno = 26, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": runtime.contextOrFrameLookup(context, frame, "iconName"),"size": 1.25}])), env.opts.autoescape);
output += "\n\n    <div class=\"nh-status-panel__content\">\n        ";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "heading")) === true && env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "heading"),"")) {
output += "\n            <span class=\"nh-status-panel__heading\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "heading"), env.opts.autoescape);
output += "</span>\n        ";
;
}
output += "\n\n        <p class=\"nh-status-panel__label\" data-role=\"status-panel-label\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "label")), env.opts.autoescape);
output += "</p>\n    </div>\n\n    ";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "cta")) === true && env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"label")) === true) {
output += "\n        ";
var t_13;
t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"label");
frame.set("label", t_13, true);
if(frame.topLevel) {
context.setVariable("label", t_13);
}
if(frame.topLevel) {
context.addExport("label", t_13);
}
output += "\n        ";
var t_14;
t_14 = env.getFilter("merge").call(context, {"data-role": "cta"},env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"data"),{}));
frame.set("data", t_14, true);
if(frame.topLevel) {
context.setVariable("data", t_14);
}
if(frame.topLevel) {
context.addExport("data", t_14);
}
output += "\n\n        ";
if(env.getTest("defined").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"href")) === true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"href")) {
output += "\n            ";
var t_15;
t_15 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"href");
frame.set("href", t_15, true);
if(frame.topLevel) {
context.setVariable("href", t_15);
}
if(frame.topLevel) {
context.addExport("href", t_15);
}
output += "\n            ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/atoms/anchor/Anchor.html.njk", false, "design-system/v3/components/molecules/status-panel/StatusPanel.html.njk", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
callback(null,t_16);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_19,t_18) {
if(t_19) { cb(t_19); return; }
callback(null,t_18);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n        ";
});
}
else {
output += "\n            ";
var t_20;
t_20 = "minimal";
frame.set("variant", t_20, true);
if(frame.topLevel) {
context.setVariable("variant", t_20);
}
if(frame.topLevel) {
context.addExport("variant", t_20);
}
output += "\n            ";
var t_21;
t_21 = "inline";
frame.set("size", t_21, true);
if(frame.topLevel) {
context.setVariable("size", t_21);
}
if(frame.topLevel) {
context.addExport("size", t_21);
}
output += "\n            ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/atoms/button/Button.html.njk", false, "design-system/v3/components/molecules/status-panel/StatusPanel.html.njk", false, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
callback(null,t_22);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
callback(null,t_24);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n        ";
});
}
output += "\n    ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/status-panel/StatusPanel.html.njk"] , dependencies)